"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _require = require('../analytics/role.constant'),
  ANALYTICS_EDGE_ROLES = _require.ANALYTICS_EDGE_ROLES;
var LABEL = {
  NODE: {
    // organizations
    GLOBAL: 'GLOBAL',
    // there should only be ONE
    CAMPUS: 'CAMPUS',
    COLLEGE: 'COLLEGE',
    DIVISION: 'DIVISION',
    DEPARTMENT: 'DEPARTMENT',
    DOMAIN: 'DOMAIN',
    COLLECTION: 'COLLECTION',
    SERVICE: 'SERVICE',
    PROGRAM: 'PROGRAM',
    PARTY: 'PARTY',
    TENANT: 'TENANT',
    UNIT: 'UNIT',
    GROUP: 'GROUP',
    DISTRICT: 'DISTRICT',
    FACILITY: 'FACILITY',
    CENTER_OF_EXCELLENCE: 'CENTER_OF_EXCELLENCE',
    // org for rolling up programs across campus/domain

    USER: 'USER',
    EMPLOYEE: 'EMPLOYEE',
    // locations
    BUILDING: 'BUILDING',
    FLOOR: 'FLOOR',
    ROOM: 'ROOM',
    // assessment
    PROGRAM_FLEX: 'PROGRAM_FLEX',
    LAB_HAZARD: 'LAB_HAZARD',
    UNIT_RISK: 'UNIT_RISK',
    BUA: 'BUA',
    RESPIRATOR: 'RESPIRATOR',
    FLEX_CONFIG: 'FLEX_CONFIG',
    ERGO: 'ERGO',
    ASSESSMENT_TEMPLATE: 'ASSESSMENT_TEMPLATE',
    ERGO_SELF_TEMPLATE: 'ERGO_SELF_TEMPLATE',
    LAB_HAZARD_CONFIG: 'LAB_HAZARD_CONFIG',
    PPE_MANAGEMENT: 'PPE_MANAGEMENT',
    UNIT_RISK_CONFIG: 'UNIT_RISK_CONFIG',
    BUA_CONFIG: 'BUA_CONFIG',
    PESTICIDE_CONFIG: 'PESTICIDE_CONFIG',
    RESPIRATOR_CONFIG: 'RESPIRATOR_CONFIG',
    // inspect
    REPORT: 'REPORT',
    QUESTIONNAIRE_SERIES: 'QUESTIONNAIRE_SERIES',
    QUESTIONNAIRE_TEMPLATE: 'QUESTIONNAIRE_TEMPLATE',
    INVENTORY: 'INVENTORY',
    STORE: 'STORE',
    STORE_ACCOUNT: 'STORE_ACCOUNT',
    // incident-mgmt
    INCIDENT_QUESTIONNAIRE: 'INCIDENT_QUESTIONNAIRE',
    INCIDENT_REPORT: 'INCIDENT_REPORT',
    WORKSTRONG_FILE: 'WORKSTRONG_FILE',
    WORKSTRONG_FEATURE: 'WORKSTRONG_FEATURE',
    // pesticide
    APPLICATOR_GROUP: 'APPLICATOR_GROUP',
    PESTICIDE_USE: 'PESTICIDE_USE',
    // pesticide - deprecated
    OPERATOR_GROUP: 'OPERATOR_GROUP',
    // Routing List
    ROUTING_LIST: 'ROUTING_LIST',
    ROUTING_GROUP: 'ROUTING_GROUP',
    // TODO: Remove later
    // Resfit
    RESFIT_QUESTIONNAIRE: 'RESFIT_QUESTIONNAIRE',
    RESFIT_TEST: 'RESFIT_TEST',
    // Fittest
    FITTEST_TEMPLATE: 'FITTEST_TEMPLATE',
    FITTEST_DOCUMENT: 'FITTEST_DOCUMENT',
    // Bucket
    BUCKET: 'BUCKET'
  },
  EDGE: _objectSpread(_objectSpread({}, ANALYTICS_EDGE_ROLES), {}, {
    // Roles Specific to Analytics

    AFFILIATED: 'AFFILIATED',
    SYSTEM_ROLE: 'SYSTEM_ROLE',
    IS_A: 'IS_A',
    APPOINTMENT_IN: 'APPOINTMENT_IN',
    DOCUMENT: 'DOCUMENT',
    FEATURE: 'FEATURE',
    LOCATED: 'LOCATED',
    VERSION: 'VERSION',
    APPROVED: 'APPROVED',
    CERTIFIED: 'CERTIFIED',
    COMPLETED: 'COMPLETED',
    ACKNOWLEDGED: 'ACKNOWLEDGED',
    LOCATION: 'LOCATION',
    DELEGATE: 'DELEGATE',
    MEMBER: 'MEMBER',
    OWNER: 'OWNER',
    COLLEAGUE: 'COLLEAGUE',
    CONTACT: 'CONTACT',
    INVENTORY_MANAGER: 'INVENTORY_MANAGER',
    // inventory -> BELONGS_TO -> store
    BELONGS_TO: 'BELONGS_TO',
    ADMINISTERED_BY: 'ADMINISTERED_BY',
    STORE_MANAGER: 'STORE_MANAGER',
    RESPONSIBLE_PERSON: 'RESPONSIBLE_PERSON',
    PROGRAM_ADMIN: 'PROGRAM_ADMIN',
    DISTRICT_ADMIN: 'DISTRICT_ADMIN',
    INSPECTOR: 'INSPECTOR',
    INSPECT_ADMIN: 'INSPECT_ADMIN',
    SUBJECT: 'SUBJECT',
    BIOSAFETY_ADMIN: 'BIOSAFETY_ADMIN',
    COLLECTION_ADMIN: 'COLLECTION_ADMIN',
    EHS_DEPARTMENT_ADMIN: 'EHS_DEPARTMENT_ADMIN',
    FLEX_EVALUATOR: 'FLEX_EVALUATOR',
    FLEX_CONSULTANT: 'FLEX_CONSULTANT',
    WAIVER_ADMIN: 'WAIVER_ADMIN',
    IBC_MEMBER: 'IBC_MEMBER',
    LAB_HAZARD_ADMIN: 'LAB_HAZARD_ADMIN',
    LHA_READ_ONLY: 'LHA_READ_ONLY',
    PPE_COORDINATOR: 'PPE_COORDINATOR',
    SAFETY_COORDINATOR: 'SAFETY_COORDINATOR',
    PROFILE_ADMIN: 'PROFILE_ADMIN',
    ROLE_MANAGER: 'ROLE_MANAGER',
    UNIT_RISK_ADMIN: 'UNIT_RISK_ADMIN',
    RESPIRATOR_ADMIN: 'RESPIRATOR_ADMIN',
    WAIVER_OBSERVER: 'WAIVER_OBSERVER',
    ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
    USER_ADMIN: 'USER_ADMIN',
    ROLE_ADMIN: 'ROLE_ADMIN',
    FACILITY_ADMIN: 'FACILITY_ADMIN',
    ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
    LOCATION_MANAGER: 'LOCATION_MANAGER',
    NURSE_MANAGER: 'NURSE_MANAGER',
    UNIT_MANAGER: 'UNIT_MANAGER',
    CHILD_ORGANIZATION: 'CHILD_ORGANIZATION',
    NOTIFICATION_MANAGER: 'NOTIFICATION_MANAGER',
    PROCEDURES_ADMIN: 'PROCEDURES_ADMIN',
    AGENDA_PROCEDURES_TEMPLATE_ADMIN: 'AGENDA_PROCEDURES_TEMPLATE_ADMIN',
    FIELD_PROCEDURES_TEMPLATE_ADMIN: 'FIELD_PROCEDURES_TEMPLATE_ADMIN',
    LOTO_PROCEDURES_TEMPLATE_ADMIN: 'LOTO_PROCEDURES_TEMPLATE_ADMIN',
    MEDICAL_PROCEDURES_TEMPLATE_ADMIN: 'MEDICAL_PROCEDURES_TEMPLATE_ADMIN',
    SPH_PROCEDURES_TEMPLATE_ADMIN: 'SPH_PROCEDURES_TEMPLATE_ADMIN',
    SOP_PROCEDURES_TEMPLATE_ADMIN: 'SOP_PROCEDURES_TEMPLATE_ADMIN',
    WPVP_PROCEDURES_TEMPLATE_ADMIN: 'WPVP_PROCEDURES_TEMPLATE_ADMIN',
    AGENDA_PROCEDURES_ADMIN: 'AGENDA_PROCEDURES_ADMIN',
    FIELD_PROCEDURES_ADMIN: 'FIELD_PROCEDURES_ADMIN',
    LOTO_PROCEDURES_ADMIN: 'LOTO_PROCEDURES_ADMIN',
    MEDICAL_PROCEDURES_ADMIN: 'MEDICAL_PROCEDURES_ADMIN',
    SPH_PROCEDURES_ADMIN: 'SPH_PROCEDURES_ADMIN',
    SOP_PROCEDURES_ADMIN: 'SOP_PROCEDURES_ADMIN',
    WPVP_PROCEDURES_ADMIN: 'WPVP_PROCEDURES_ADMIN',
    INITIAL_REVIEWER: 'INITIAL_REVIEWER',
    FINAL_REVIEWER: 'FINAL_REVIEWER',
    RADIATION_ADMIN: 'Radiation Admin',
    RADIATION_EMAIL_LIST: 'Radiation Email List',
    RADIATION_INSPECTOR: 'Radiation Inspector',
    // maq
    CONTROL_AREA_ADMIN: 'CONTROL_AREA_ADMIN',
    FIRE_CODE_ADMIN: 'FIRE_CODE_ADMIN',
    CHEMICAL_ADMIN: 'CHEMICAL_ADMIN',
    CHEMICAL_ADMIN_READ_ONLY: 'CHEMICAL_ADMIN_READ_ONLY',
    CHEMICAL_INFORMATICS: 'CHEMICAL_INFORMATICS',
    GROUP: 'GROUP',
    INVENTORY_SPECIALIST: 'INVENTORY_SPECIALIST',
    MEDICAL_ADMIN: 'MEDICAL_ADMIN',
    RESPIRATOR_PROGRAM_ADMIN: 'RESPIRATOR_PROGRAM_ADMIN',
    SYSTEM_CONTROL_AREA_ADMIN: 'SYSTEM_CONTROL_AREA_ADMIN',
    SERVICE_DESK: 'SERVICE_DESK',
    CONFIGURATION_MANAGER: 'CONFIGURATION_MANAGER',
    RSS_DATA_ADMIN: 'RSS_DATA_ADMIN',
    RSS_DATA_ADMIN_LII: 'RSS_DATA_ADMIN_LII',
    BSAS_ADMIN: 'BSAS_ADMIN',
    CLI_ADMIN: 'CLI_ADMIN',
    DRONES_ADMIN: 'DRONES_ADMIN',
    DRONES_TENANT_ADMIN: 'DRONES_TENANT_ADMIN',
    BSAS_TENANT_ADMIN: 'BSAS_TENANT_ADMIN',
    NFPA_ADMIN: 'NFPA_ADMIN',
    // pesticide
    APPLICATOR_GROUP_ADMIN: 'APPLICATOR_GROUP_ADMIN',
    IPM_COORDINATOR: 'IPM_COORDINATOR',
    IPMC_MEMBER: 'IPMC_MEMBER',
    LIBRARY_MANAGER: 'LIBRARY_MANAGER',
    APPLICATION_SUPERVISOR: 'APPLICATION_SUPERVISOR',
    PESTICIDE_HANDLER: 'PESTICIDE_HANDLER',
    PESTICIDE_APPLICATOR: 'PESTICIDE_APPLICATOR',
    // incident-mgmt
    CLAIMS_ANALYST: 'CLAIMS_ANALYST',
    CLAIMS_ANALYST_READ_ONLY: 'CLAIMS_ANALYST_READ_ONLY',
    INJURED_PERSON: 'INJURED_PERSON',
    REPORTER: 'REPORTER',
    SUPERVISOR: 'SUPERVISOR',
    INVESTIGATOR: 'INVESTIGATOR',
    CHEMICAL_HAZARD_CONTACT: 'CHEMICAL_HAZARD_CONTACT',
    EQUIPMENT_FAILURE_CONTACT: 'EQUIPMENT_FAILURE_CONTACT',
    BIO_HAZARD_CONTACT: 'BIO_HAZARD_CONTACT',
    RADIATION_HAZARD_CONTACT: 'RADIATION_HAZARD_CONTACT',
    // analytics
    AWAY_ADMIN: 'AWAY_ADMIN',
    EFR_ADMIN: 'EFR_ADMIN',
    INSPECT_ANALYTICS: 'INSPECT_ANALYTICS',
    MONITOR_ADMIN: 'MONITOR_ADMIN',
    TRAINING_ADMIN: 'TRAINING_ADMIN',
    WASTE_ADMIN: 'WASTE_ADMIN',
    WASTE_STAFF: 'WASTE_STAFF',
    WSSP_PROCEDURES_ADMIN: 'WSSP_PROCEDURES_ADMIN',
    // workstrong
    PARTICIPANT: 'PARTICIPANT',
    PHYSICIAN: 'PHYSICIAN',
    WELLNESS_TRAINER: 'WELLNESS_TRAINER',
    WORKSTRONG_COORDINATOR: 'WORKSTRONG_COORDINATOR',
    WORKSTRONG_TPA: 'WORKSTRONG_TPA',
    COE_ADMIN: 'COE_ADMIN',
    // for merge room
    MERGED: 'MERGED',
    // Onboarding
    CLIENT_ADMIN: 'CLIENT_ADMIN',
    // Ergo
    ASSESSMENT_ADMIN: 'ASSESSMENT_ADMIN',
    ASSESSMENT_READ_ONLY: 'ASSESSMENT_READ_ONLY',
    // Resfit
    RESFIT_ADMIN: 'RESFIT_ADMIN',
    FIT_TESTER: 'FIT_TESTER',
    FITTEST_SUPERVISOR: 'FITTEST_SUPERVISOR'
  }),
  EDGE_TYPE: {
    EVENT: 'EVENT',
    ROLE: 'ROLE',
    OTHER: 'OTHER'
  }
};
module.exports = _objectSpread({}, LABEL);