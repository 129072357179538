"use strict";

var _label = _interopRequireDefault(require("../relationship/label.constant"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
module.exports = {
  id: 'VUN8MDN8c3lzdGVt',
  userId: 'VUN8MDN8c3lzdGVt',
  label: _label["default"].NODE.USER,
  firstName: 'RSS',
  lastName: 'System',
  tenantCode: 'GLOBAL',
  campusCode: 'GLOBAL'
};