import PropTypes from 'prop-types';
import React, { useState } from 'react';

import config from '../config';
import Upload from './Upload';

const AttachFileToS3 = ({
  accept,
  buttonLabel,
  children,
  disabled,
  feature,
  id,
  label,
  multiple,
  optional,
  onChange,
  variant,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleUpload = async (files) => {
    const uploadedFiles = await Promise.all(files.map((file) => uploadFile(file)));
    setIsLoading(false);
    onChange(uploadedFiles);
  };

  const uploadFile = async (file) => {
    setIsLoading(true);

    const data = new FormData();
    data.append('uploadFile', file, file.name);
    data.append('feature', feature);

    const options = {
      method: 'POST',
      body: data,
    };

    try {
      const response = await fetch(`${config.DRIVE_URL}/uploadFileToS3FromClient`, options);
      if (!response.ok) {
        throw new Error('Failed to upload file');
      }
      const result = await response.json();
      console.log('Upload successful:', result);
      setIsLoading(false);
      return result;
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsLoading(false);
      return null;
    }
  };

  return (
    <Upload
      accept={accept}
      buttonLabel={buttonLabel}
      label={label}
      multiple={multiple}
      disabled={disabled || isLoading}
      id={id}
      optional={optional}
      onUpload={handleUpload}
      variant={variant}>
      {children}
    </Upload>
  );
};

AttachFileToS3.propTypes = {
  accept: PropTypes.string,
  buttonLabel: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  feature: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  variant: PropTypes.oneOf(['dropzone', 'icon']),
  feature: PropTypes.string,
};

AttachFileToS3.defaultProps = {
  accept: 'image/*,.csv,.xls,.xlsx,.doc,.docx,.pdf,.txt',
  buttonLabel: 'Upload File',
  children: null,
  disabled: false,
  id: '',
  label: 'Drag and drop some files here, or click to select files',
  multiple: true,
  optional: false,
  onChange: () => null,
  variant: 'dropzone',
};

export default AttachFileToS3;
