const {
  ObjectId
} = require('bson');
const tv4 = require('tv4');
const clone = require('clone');
class LineItem {
  constructor(opt) {
    this._id = null;
    this.description = null;
    this.catalogNumber = null;
    this.vendor = null;
    this.cas = [];
    this.quantity = 1;
    this.amount = null;
    this.units = null;
    this.details = null;
    // this.price = null

    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this._id = new ObjectId(opt._id);
    this.description = opt.description || null;
    this.catalogNumber = opt.catalogNumber || null;
    this.vendor = opt.vendor || null;
    this.cas = opt.cas || null;
    this.quantity = opt.quantity || 1;
    this.amount = opt.amount || null;
    this.units = opt.units || null;
    this.details = opt.details || null;
    // this.price = null
  }
  validate() {
    return tv4.validateMultiple(this, LineItem.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'line-item.schema.json',
      type: 'object',
      additionalProperties: false,
      properties: {
        _id: {
          type: 'object'
        },
        description: {
          type: ['string', 'null']
        },
        catalogNumber: {
          type: ['string', 'null']
        },
        vendor: {
          type: ['string', 'null']
        },
        cas: {
          type: 'array',
          items: {
            type: 'string'
          }
        },
        quantity: {
          type: 'number'
        },
        amount: {
          type: ['number', 'null']
        },
        units: {
          type: 'string'
        },
        details: {
          type: ['object', 'null']
        }
      },
      required: ['_id', 'amount', 'units']
    };
  }
}
module.exports = LineItem;