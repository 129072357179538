"use strict";

function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var _require = require('lodash'),
  eq = _require.eq,
  get = _require.get;
module.exports.getUsersByRoles = function (relationships) {
  var roles = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return relationships.filter(function (_ref) {
    var edge = _ref.edge;
    return roles.includes(get(edge, 'label'));
  }).map(function (_ref2) {
    var _ref2$node = _ref2.node,
      firstName = _ref2$node.firstName,
      id = _ref2$node.id,
      lastName = _ref2$node.lastName,
      email = _ref2$node.email;
    return {
      userId: id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      fullName: "".concat(firstName, " ").concat(lastName)
    };
  });
};
module.exports.parsePersonNodeToUser = function (person) {
  return {
    userId: person.nodeId || person.node.id,
    firstName: (person.label || person.node).firstName,
    lastName: (person.label || person.node).lastName,
    email: (person.label || person.node).email,
    fullName: "".concat((person.label || person.node).firstName, " ").concat((person.label || person.node).lastName)
  };
};
module.exports.hasRelationshipWithRole = function (id) {
  var relationships = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var role = arguments.length > 2 ? arguments[2] : undefined;
  if (!id) {
    return false;
  }
  var roles = typeof role === 'string' ? [role] : _toConsumableArray(role);
  return relationships.filter(function (r) {
    return get(r, 'edge.isActive');
  }).some(function (r) {
    return get(r, 'node.id') === id && roles.includes(get(r, 'edge.label'));
  });
};
module.exports.hasAnyOfRoles = function (relationships, roles) {
  return relationships.some(function (r) {
    return roles.includes(r.role) || roles.includes(get(r, 'edge.label'));
  });
};
module.exports.hasAnyOfTypes = function (relationships, types) {
  return relationships.some(function (r) {
    return types.includes(r.type) || types.includes(get(r, 'node.label'));
  });
};
module.exports.hasAnyOfTypesWithRoles = function (relationships, types) {
  var roles = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  return relationships.some(function (r) {
    return (types.includes(r.type) || types.includes(get(r, 'node.label'))) && (roles.includes(r.role) || roles.includes(get(r, 'edge.label')));
  });
};
module.exports.hasRelationship = function (relationships, id) {
  return relationships.filter(function (r) {
    return get(r, 'edge.isActive');
  }).some(function (r) {
    return get(r, 'node.id') === id;
  });
};
module.exports.hasNodeLabel = function (nodeLabel, label) {
  if (!nodeLabel) return false;
  if (!label) return false;
  if (eq(nodeLabel, label)) return true;
  var labels = Array.isArray(nodeLabel) ? nodeLabel : nodeLabel.split('::');
  return labels.some(function (l) {
    return l === label;
  });
};