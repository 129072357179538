/* eslint-disable max-len */
const config = {
  localhost: {
    ENV: 'dev',
    LIMIT: 50,
    NOT_PROD: true,
    NEPTUNE: true,
    ANALYTICS_URL: '/analytics',
    ANALYTICS_V2_URL: '/analytics',
    CHEM_ADMIN_URL: '/chemical-admin',
    CHEMICALS_URL: 'https://dev.riskandsafety.com/chemicals',
    BSAS_URL: 'http://localhost:8095/bsas',
    NFPA_URL: 'http://localhost:8075/nfpa',
    CLI_URL: 'http://localhost:4200',
    GRAPH_URL: 'http://localhost:3001',
    DRIVE_URL: 'https://dev.riskandsafety.com/drive/api',
    // DRIVE_URL: 'http://localhost:3003',
    HELP_URL: '/help',
    HELP_SUBMIT_TICKET_URL:
      'https://support.dev.riskandsafety.com/rss_support?id=rss_help_id&sys_id=84817e251bb5e89007d49608bd4bcb9f',
    HELP_SUBMIT_TICKET_URL_EXTERNAL:
      'https://support.dev.riskandsafety.com/rss_support?id=rss_help&sys_id=2d38e9a51b8b1c106a9399f4bd4bcb52',
    FORM_BUILDER_GRAPHQL_URL: 'https://dev.riskandsafety.com/form-builder/graphql',
    INCIDENT_MGMT_URL: 'http://localhost:3029',
    INCIDENT_MGMT_V2_URL: 'http://localhost:4029',
    INSPECTION_TOOL_WC_URL: 'https://dev.riskandsafety.com/web-components/inspection',
    // INSPECTION_TOOL_WC_URL: 'http://localhost:3302',
    INSPECTION_TOOL_URL: 'https://dev.riskandsafety.com/inspection',
    // INSPECTION_TOOL_URL: 'http://localhost:4000/graphql',
    PROFILE_URL: '/profile',
    PRINTABLE_URL: 'http://localhost:3023',
    BRANDING_URL: 'http://localhost:3010/api',
    BRANDING_GRAPH_URL: 'http://localhost:3010/graphql',
    // MAQ_GRAPH_URL: 'http://localhost:8081/graphql',
    MAQ_GRAPH_URL: 'https://dev.riskandsafety.com/maq/graphql',
    RELATIONSHIP_GRAPH_URL: 'https://dev.riskandsafety.com/relationship-v2/graphql',
    SOCKET_URL: 'wss://ws.dev.riskandsafety.com',
    // SOCKET_URL: 'ws://localhost:8001',
    TRAINING_API: 'https://dev.riskandsafety.com/learning',
    PUBLIC_URL: 'https://dev.riskandsafety.com',
    RSS_APP_BUCKET: 'rss-app-dev',
    // RSS_WC_URL: 'http://localhost:8888',
    RSS_WC_URL: 'https://dev.riskandsafety.com/web-components',
    COGNITO_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_HG4jXkc8G',
      userPoolWebClientId: '4iq22397h9cfl1l3iiejbcfusn',
      authenticationFlowType: 'USER_SRP_AUTH',
      cookieStorage: {
        domain: document.domain,
        path: '/',
        expires: null,
        secure: false,
      },
      oauth: {
        domain: 'auth.dev.riskandsafety.com',
        scope: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/logout`,
        responseType: 'code',
      },
    },
    COGNITO_INVITATION_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_I8jBjHsxJ',
      userPoolWebClientId: '1nq7vgbr6qu5biemisj1srptt',
      authenticationFlowType: 'USER_SRP_AUTH',
      storage: window.sessionStorage,
      oauth: {
        domain: 'invite-dev.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}/public/invitation`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/`,
        responseType: 'code',
      },
    },
    LD_CLIENT_ID: '6463ffb9f8cf2311effb5461',
    MOODLE_CLIENT_ID: '4l6qphclqv9ef1o2fm9g6hinem',
    SPOOF_USER_KEY: 'RSS:SPOOF:USER',
    SELECTED_IDP_KEY: 'RSS:SELECTED:IDP',
    SERVICE_NOW_AUTH_URL:
      'https://ucermdev.service-now.com/login_with_sso.do?glide_sso_id=a4b0cc3bdb48641041e4236ed39619b6',
    SERVICE_NOW_AUTH_RSS_URL:
      'https://support.dev.riskandsafety.com/login_with_sso.do?glide_sso_id=fa4418d71b58e01028c763d07e4bcb7c',
    ANALYTICS_TRAINING: {
      GROUP: {
        analyticsId: '77f3926b-9dc5-4730-992f-e83a78264358',
        workspaceId: '28dcaa9f-5c1c-4f5a-97bf-a4ffa5fc6e6a',
      },
    },
    ASSESSMENT_ELASTIC_ENABLED: false,
    CAL_AG_PUR_URL: 'https://calagtest.calicosol.com/PesticideUseReport.aspx?ReportID=',
    CAL_AG_NOI_URL: 'https://calagtest.calicosol.com/NoticeOfIntent.aspx?ReportID=',
    CAL_AG_MSPUR_URL: 'https://calagtest.calicosol.com/PURMonthlySummary.aspx?ReportID=',
  },
  'hc-test': {
    ENV: 'dev',
    LIMIT: 50,
    NOT_PROD: true,
    NEPTUNE: true,
    ANALYTICS_URL: '/analytics',
    ANALYTICS_V2_URL: '/analytics',
    CHEM_ADMIN_URL: '/chemical-admin',
    CHEMICALS_URL: '/chemicals',
    BSAS_URL: '/bsas/api',
    CLI_URL: '/cli/api',
    NFPA_URL: '/nfpa/api',
    GRAPH_URL: '/graph/api',
    DRIVE_URL: '/drive/api',
    HELP_URL: '/help',
    HELP_SUBMIT_TICKET_URL:
      'https://support.riskandsafety.com/rss_support?id=rss_help_id&sys_id=84817e251bb5e89007d49608bd4bcb9f&hcapp=true',
    HELP_SUBMIT_TICKET_URL_EXTERNAL:
      'https://support.riskandsafety.com/rss_support?id=rss_help&sys_id=2d38e9a51b8b1c106a9399f4bd4bcb52&hcapp=true',
    PROFILE_URL: '/profile',
    PRINTABLE_URL: '/printable/api',
    BRANDING_URL: '/branding/api',
    BRANDING_GRAPH_URL: '/branding/graphql',
    MAQ_GRAPH_URL: '/maq/graphql',
    RELATIONSHIP_GRAPH_URL: '/relationship-v2/graphql',
    TRAINING_API: '/learning',
    RSS_APP_BUCKET: 'rss-app-dev',
    RSS_WC_URL: '/web-components',
    COGNITO_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_HG4jXkc8G',
      userPoolWebClientId: '8il5qps36agta2degkr6s1scg',
      authenticationFlowType: 'USER_SRP_AUTH',
      cookieStorage: {
        domain: document.domain,
        path: '/',
        expires: null,
        secure: true,
      },
      oauth: {
        domain: 'auth.dev.riskandsafety.com',
        scope: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/logout`,
        responseType: 'code',
      },
    },
    COGNITO_INVITATION_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-us-west-2_I8jBjHsxJ',
      userPoolWebClientId: '7ndfi6tbcbkso2ujg0rn4mrllr',
      authenticationFlowType: 'USER_SRP_AUTH',
      storage: window.sessionStorage,
      oauth: {
        domain: 'invite-dev.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}/public/invitation`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/`,
        responseType: 'code',
      },
    },
    MOODLE_CLIENT_ID: '4l6qphclqv9ef1o2fm9g6hinem',
    SPOOF_USER_KEY: 'RSS:SPOOF:USER',
    SELECTED_IDP_KEY: 'RSS:SELECTED:IDP',
  },
  dev: {
    ENV: 'dev',
    LIMIT: 50,
    NOT_PROD: true,
    NEPTUNE: true,
    ANALYTICS_URL: '/analytics',
    ANALYTICS_V2_URL: '/analytics',
    CHEM_ADMIN_URL: '/chemical-admin',
    CHEMICALS_URL: '/chemicals',
    BSAS_URL: '/bsas/api',
    CLI_URL: '/cli/api',
    NFPA_URL: '/nfpa/api',
    GRAPH_URL: '/graph/api',
    DRIVE_URL: '/drive/api',
    HELP_URL: '/help',
    HELP_SUBMIT_TICKET_URL:
      'https://support.dev.riskandsafety.com/rss_support?id=rss_help_id&sys_id=84817e251bb5e89007d49608bd4bcb9f',
    HELP_SUBMIT_TICKET_URL_EXTERNAL:
      'https://support.dev.riskandsafety.com/rss_support?id=rss_help&sys_id=2d38e9a51b8b1c106a9399f4bd4bcb52',
    FORM_BUILDER_GRAPHQL_URL: '/form-builder/graphql',
    INCIDENT_MGMT_URL: '/incident-mgmt/api',
    INCIDENT_MGMT_V2_URL: '/incident-mgmt-v2',
    INSPECTION_TOOL_WC_URL: '/web-components/inspection',
    // FIXME: change when deployments pr is merged https://github.com/risk-and-safety/deployments/pull/2522
    INSPECTION_TOOL_URL: '/inspection',
    SOCKET_URL: 'wss://ws.dev.riskandsafety.com',
    PROFILE_URL: '/profile',
    PRINTABLE_URL: '/printable/api',
    BRANDING_URL: '/branding/api',
    BRANDING_GRAPH_URL: '/branding/graphql',
    MAQ_GRAPH_URL: '/maq/graphql',
    RELATIONSHIP_GRAPH_URL: '/relationship-v2/graphql',
    TRAINING_API: '/learning',
    RSS_APP_BUCKET: 'rss-app-dev',
    RSS_WC_URL: '/web-components',
    COGNITO_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_HG4jXkc8G',
      userPoolWebClientId: '8il5qps36agta2degkr6s1scg',
      authenticationFlowType: 'USER_SRP_AUTH',
      cookieStorage: {
        domain: document.domain,
        path: '/',
        expires: null,
        secure: true,
      },
      oauth: {
        domain: 'auth.dev.riskandsafety.com',
        scope: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/logout`,
        responseType: 'code',
      },
    },
    COGNITO_INVITATION_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-us-west-2_I8jBjHsxJ',
      userPoolWebClientId: '7ndfi6tbcbkso2ujg0rn4mrllr',
      authenticationFlowType: 'USER_SRP_AUTH',
      storage: window.sessionStorage,
      oauth: {
        domain: 'invite-dev.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}/public/invitation`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/`,
        responseType: 'code',
      },
    },
    LD_CLIENT_ID: '6463ffb9f8cf2311effb5461',
    MOODLE_CLIENT_ID: '4l6qphclqv9ef1o2fm9g6hinem',
    SPOOF_USER_KEY: 'RSS:SPOOF:USER',
    SELECTED_IDP_KEY: 'RSS:SELECTED:IDP',
    SERVICE_NOW_AUTH_URL:
      'https://ucermdev.service-now.com/login_with_sso.do?glide_sso_id=a4b0cc3bdb48641041e4236ed39619b6',
    SERVICE_NOW_AUTH_RSS_URL:
      'https://support.dev.riskandsafety.com/login_with_sso.do?glide_sso_id=fa4418d71b58e01028c763d07e4bcb7c',
    ANALYTICS_TRAINING: {
      GROUP: {
        analyticsId: '77f3926b-9dc5-4730-992f-e83a78264358',
        workspaceId: '28dcaa9f-5c1c-4f5a-97bf-a4ffa5fc6e6a',
      },
    },
    ASSESSMENT_ELASTIC_ENABLED: true,
    CAL_AG_PUR_URL: 'https://calagtest.calicosol.com/PesticideUseReport.aspx?ReportID=',
    CAL_AG_NOI_URL: 'https://calagtest.calicosol.com/NoticeOfIntent.aspx?ReportID=',
    CAL_AG_MSPUR_URL: 'https://calagtest.calicosol.com/PURMonthlySummary.aspx?ReportID=',
  },
  qa: {
    ENV: 'qa',
    LIMIT: 50,
    NOT_PROD: true,
    NEPTUNE: true,
    ANALYTICS_URL: '/analytics',
    ANALYTICS_V2_URL: '/analytics',
    CHEM_ADMIN_URL: '/chemical-admin',
    CHEMICALS_URL: '/chemicals',
    BSAS_URL: '/bsas/api',
    CLI_URL: '/cli/api',
    NFPA_URL: '/nfpa/api',
    GRAPH_URL: '/graph/api',
    DRIVE_URL: '/drive/api',
    FORM_BUILDER_GRAPHQL_URL: '/form-builder/graphql',
    INCIDENT_MGMT_URL: '/incident-mgmt/api',
    INCIDENT_MGMT_V2_URL: '/incident-mgmt-v2',
    HELP_URL: '/help',
    HELP_SUBMIT_TICKET_URL:
      'https://support.qa.riskandsafety.com/rss_support?id=rss_help_id&sys_id=84817e251bb5e89007d49608bd4bcb9f',
    HELP_SUBMIT_TICKET_URL_EXTERNAL:
      'https://support.qa.riskandsafety.com/rss_support?id=rss_help&sys_id=2d38e9a51b8b1c106a9399f4bd4bcb52',
    SOCKET_URL: 'wss://ws.qa.riskandsafety.com',
    INSPECTION_TOOL_WC_URL: '/web-components/inspection',
    INSPECTION_TOOL_URL: '/inspection',
    PROFILE_URL: '/profile',
    PRINTABLE_URL: '/printable/api',
    BRANDING_URL: '/branding/api',
    BRANDING_GRAPH_URL: '/branding/graphql',
    MAQ_GRAPH_URL: '/maq/graphql',
    RELATIONSHIP_GRAPH_URL: '/relationship-v2/graphql',
    TRAINING_API: '/learning',
    RSS_APP_BUCKET: 'rss-app-qa',
    RSS_WC_URL: '/web-components',
    COGNITO_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_bIillxdK1',
      userPoolWebClientId: 'usu33q80fg4o0mnsrgkli5nie',
      authenticationFlowType: 'USER_SRP_AUTH',
      cookieStorage: {
        domain: document.domain,
        path: '/',
        expires: null,
        secure: true,
      },
      oauth: {
        domain: 'auth.qa.riskandsafety.com',
        scope: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/logout`,
        responseType: 'code',
      },
    },
    COGNITO_INVITATION_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_qaTNgaNEL',
      userPoolWebClientId: '1vsgccf3cnovpn3splkgvhe2ke',
      authenticationFlowType: 'USER_SRP_AUTH',
      storage: window.sessionStorage,
      oauth: {
        domain: 'invite-qa.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}/public/invitation`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/`,
        responseType: 'code',
      },
    },
    LD_CLIENT_ID: '65725f7f5a57c71063856450',
    MOODLE_CLIENT_ID: '4uub8bfd1nu84db7i0nfq76tfp',
    SPOOF_USER_KEY: 'RSS:SPOOF:USER',
    SERVICE_NOW_AUTH_URL:
      'https://ucermtest.service-now.com/login_with_sso.do?glide_sso_id=9118b02a1bfc6c10674286ae6e4bcb55',
    SERVICE_NOW_AUTH_RSS_URL:
      'https://support.qa.riskandsafety.com/login_with_sso.do?glide_sso_id=466289e61bb0ac10674286ae6e4bcb5e',
    SELECTED_IDP_KEY: 'RSS:SELECTED:IDP',
    ASSESSMENT_ELASTIC_ENABLED: false,
    CAL_AG_PUR_URL: 'https://calagtest.calicosol.com/PesticideUseReport.aspx?ReportID=',
    CAL_AG_NOI_URL: 'https://calagtest.calicosol.com/NoticeOfIntent.aspx?ReportID=',
    CAL_AG_MSPUR_URL: 'https://calagtest.calicosol.com/PURMonthlySummary.aspx?ReportID=',
  },
  prod: {
    ENV: 'prod',
    LIMIT: 50,
    NOT_PROD: false,
    NEPTUNE: true,
    ANALYTICS_URL: '/analytics',
    ANALYTICS_V2_URL: '/analytics',
    CHEM_ADMIN_URL: '/chemical-admin',
    CHEMICALS_URL: '/chemicals',
    BSAS_URL: '/bsas/api',
    CLI_URL: '/cli/api',
    NFPA_URL: '/nfpa/api',
    GRAPH_URL: '/graph/api',
    FORM_BUILDER_GRAPHQL_URL: '/form-builder/graphql',
    INCIDENT_MGMT_URL: '/incident-mgmt/api',
    INCIDENT_MGMT_V2_URL: '/incident-mgmt-v2',
    INSPECTION_TOOL_WC_URL: '/web-components/inspection',
    INSPECTION_TOOL_URL: '/inspection',
    DRIVE_URL: '/drive/api',
    HELP_URL: '/help',
    HELP_SUBMIT_TICKET_URL:
      'https://support.riskandsafety.com/rss_support?id=rss_help_id&sys_id=84817e251bb5e89007d49608bd4bcb9f',
    HELP_SUBMIT_TICKET_URL_EXTERNAL:
      'https://support.riskandsafety.com/rss_support?id=rss_help&sys_id=2d38e9a51b8b1c106a9399f4bd4bcb52',
    SOCKET_URL: 'wss://ws.app.riskandsafety.com',
    PROFILE_URL: '/profile',
    PRINTABLE_URL: '/printable/api',
    BRANDING_URL: '/branding/api',
    BRANDING_GRAPH_URL: '/branding/graphql',
    MAQ_GRAPH_URL: '/maq/graphql',
    RELATIONSHIP_GRAPH_URL: '/relationship-v2/graphql',
    TRAINING_API: '/learning',
    RSS_APP_BUCKET: 'rss-app-prod',
    RSS_WC_URL: '/web-components',
    COGNITO_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_s6n7nvnyK',
      userPoolWebClientId: '69v6qi0s1oqqknq8is35r6bl6v',
      authenticationFlowType: 'USER_SRP_AUTH',
      cookieStorage: {
        domain: document.domain,
        path: '/',
        expires: null,
        secure: true,
      },
      oauth: {
        domain: 'auth.app.riskandsafety.com',
        scope: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/logout`,
        responseType: 'code',
      },
    },
    COGNITO_INVITATION_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_yDxNeUPJh',
      userPoolWebClientId: '3m3cv2an4rmf39vb4mf0eohrou',
      authenticationFlowType: 'USER_SRP_AUTH',
      storage: window.sessionStorage,
      oauth: {
        domain: 'rss-prod-invitation.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}/public/invitation`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/`,
        responseType: 'code',
      },
    },
    LD_CLIENT_ID: '645435c3a3e99a12481ad140',
    MOODLE_CLIENT_ID: '7q3tvh1l566j1e6idrk1n0rev1',
    SPOOF_USER_KEY: 'RSS:SPOOF:USER',
    SERVICE_NOW_AUTH_URL:
      'https://ucerm.service-now.com/login_with_sso.do?glide_sso_id=749a9e2c1b86641007d49608bd4bcb82',
    SERVICE_NOW_AUTH_RSS_URL:
      'https://support.riskandsafety.com/login_with_sso.do?glide_sso_id=2941edbc1b5ac110393998e1b24bcb9f',
    SELECTED_IDP_KEY: 'RSS:SELECTED:IDP',
    ASSESSMENT_ELASTIC_ENABLED: false,
    CAL_AG_PUR_URL: 'https://www.calagpermits.org/PesticideUseReport.aspx?ReportID=',
    CAL_AG_NOI_URL: 'https://www.calagpermits.org/NoticeOfIntent.aspx?ReportID=',
    CAL_AG_MSPUR_URL: 'https://www.calagpermits.org/PURMonthlySummary.aspx?ReportID=',
  },
  hc: {
    ENV: 'hc',
    LIMIT: 50,
    NOT_PROD: false,
    NEPTUNE: true,
    ANALYTICS_URL: '/analytics',
    ANALYTICS_V2_URL: '/analytics',
    CHEM_ADMIN_URL: '/chemical-admin',
    CHEMICALS_URL: '/chemicals',
    BSAS_URL: '/bsas/api',
    CLI_URL: '/cli/api',
    NFPA_URL: '/nfpa/api',
    GRAPH_URL: '/graph/api',
    FORM_BUILDER_GRAPHQL_URL: '/form-builder/graphql',
    DRIVE_URL: '/drive/api',
    HELP_URL: '/help',
    HELP_SUBMIT_TICKET_URL:
      'https://support.riskandsafety.com/rss_support?id=rss_help_id&sys_id=84817e251bb5e89007d49608bd4bcb9f&hcapp=true',
    HELP_SUBMIT_TICKET_URL_EXTERNAL:
      'https://support.riskandsafety.com/rss_support?id=rss_help&sys_id=2d38e9a51b8b1c106a9399f4bd4bcb52&hcapp=true',
    SOCKET_URL: 'wss://ws.hc.riskandsafety.com',
    PROFILE_URL: '/profile',
    PRINTABLE_URL: '/printable/api',
    BRANDING_URL: '/branding/api',
    BRANDING_GRAPH_URL: '/branding/graphql',
    MAQ_GRAPH_URL: '/maq/graphql',
    RELATIONSHIP_GRAPH_URL: '/relationship-v2/graphql',
    INSPECTION_TOOL_WC_URL: '/web-components/inspection',
    INSPECTION_TOOL_URL: '/inspection',
    TRAINING_API: '/learning',
    RSS_APP_BUCKET: 'rss-app-hc',
    RSS_WC_URL: '/web-components',
    COGNITO_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_VREa98q6r',
      userPoolWebClientId: '1p05kjf1u79medoepmgs8p6bqb',
      authenticationFlowType: 'USER_SRP_AUTH',
      cookieStorage: {
        domain: document.domain,
        path: '/',
        expires: null,
        secure: true,
      },
      oauth: {
        domain: 'auth.hc.riskandsafety.com',
        scope: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/logout`,
        responseType: 'code',
      },
    },
    COGNITO_INVITATION_AUTH: {
      region: 'us-west-2',
      userPoolId: 'us-west-2_mVVXQnCTU',
      userPoolWebClientId: '3lcfdji5he9v5c4elt02jgiadn',
      authenticationFlowType: 'USER_SRP_AUTH',
      storage: window.sessionStorage,
      oauth: {
        domain: 'invite-hc.auth.us-west-2.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: `${document.location.protocol}//${document.location.host}/public/invitation`,
        redirectSignOut: `${document.location.protocol}//${document.location.host}/`,
        responseType: 'code',
      },
    },
    LD_CLIENT_ID: '645435c3a3e99a12481ad140',
    MOODLE_CLIENT_ID: '',
    SPOOF_USER_KEY: 'RSS:SPOOF:USER',
    SERVICE_NOW_AUTH_URL:
      'https://ucerm.service-now.com/login_with_sso.do?glide_sso_id=967570e31b636410393998e1b24bcb62',
    SERVICE_NOW_AUTH_RSS_URL:
      'https://support.riskandsafety.com/login_with_sso.do?glide_sso_id=c092e9fc1b5ac110393998e1b24bcb88',
    SELECTED_IDP_KEY: 'RSS:SELECTED:IDP',
    ASSESSMENT_ELASTIC_ENABLED: false, // TODO: Need to remove this flag once elastic changes are released
  },
};

export const DEBOUNCE_TIME = 400;

const [host1, host2] = window.location.hostname.split('.');

export default config[host1] || config[host2] || config.prod;
